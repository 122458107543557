(<template>
  <form-template :title="title"
                 :progress="progressActive">
    <div class="choose-gender"
         @keydown.enter.stop.prevent="sendData">
      <p class="choose-gender__explanation">{{ $gettext('We need this information because some customers wish to use a specific gender for some assignments') }}</p>
      <sk-select :default-value="genderPlaceholder"
                 :description="genderDescr"
                 :type="'gender'"
                 :preselected-value="sex"
                 :validation-name="sexErrorsName"
                 class="choose-gender__gender-select"
                 @csvaluechanged="setGender" />
      <button ref="submitBtn"
              :disabled="disableSubmitBtn"
              type="submit"
              class="sk-btn sk-btn--default choose-gender__submit-btn"
              @click="sendData">{{ $gettext('Create account') }}</button>
    </div>
  </form-template>
</template>)

<script>
  import {mapGetters, mapState, mapMutations} from 'vuex';
  import WizardFormTemplate from '@/components/wizard_components/WizardFormTemplate';

  export default {
    components: {
      'form-template': WizardFormTemplate
    },
    data() { return {progressActive: false}; },
    computed: {
      ...mapGetters({
        userUid: 'UserInfoStore/userUid',
        userIsNotInterpreter: 'UserInfoStore/userIsNotInterpreter'
      }),
      ...mapState('TTWizardStore', {
        sex: (state) => state.stepsData.gender.sex,
        sexErrors: (state) => state.errors.sex.errors,
        sexErrorsName: (state) => state.errors.sex.name
      }),
      title() { return this.$gettext('What is your gender'); },
      genderDescr() { return this.$gettext('Gender'); },
      genderPlaceholder() { return this.$gettext('Select'); },
      disableSubmitBtn() {
        return !this.sex;
      }
    },
    methods: {
      ...mapMutations('TTWizardStore', [
        'setError',
        'removeOneError',
        'removeErrors',
        'setSex'
      ]),
      setGender(value) {
        this.setSex(value);
        this.setFocusOnSubmitBtn();
      },
      isAnyError() {
        this.removeErrors();
        let indicator = false;
        if (!this.sex) {
          this.setError({
            fieldName: 'sex',
            errorText: this.$gettext('Please choose a gender')
          });
          indicator = true;
        }

        this.$store.commit('ErrorsStore/setError', {name: this.sexErrorsName, errors: this.sexErrors});

        return indicator;
      },
      sendData() {
        if (!this.isAnyError()) {
          const obj = new FormData();
          obj.append('interpreter[person][sex]', this.sex);
          this.progressActive = true;
          this.$store.dispatch('EditProfileStore/updateUserProfile', {
            id: this.userUid,
            form: obj
          }).then(() => {
            this.progressActive = false;
            this.$router.push(this.$makeRoute({name: 'ServerHome'}));
          });
        }
      },
      setFocusOnSubmitBtn() {
        setTimeout(() => {
          this.$refs.submitBtn.focus();
        });
      }
    }
  };
</script>

<style scoped>
  .choose-gender {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: left;
  }

  .choose-gender__explanation {
    width: 350px;
    max-width: 100%;
    margin-bottom: 30px;
    font-size: 12px;
    text-align: center;
  }

  .choose-gender__gender-select {
    width: 150px;
    margin-bottom: 100px;
  }

  .choose-gender__submit-btn {
    width: 300px;
    max-width: 100%;
    margin: 0 auto;
    margin-top: 20px;
  }

  @media (max-width: 767px) {
    .choose-gender {
      padding: 0 15px;
    }
  }
</style>
